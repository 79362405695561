import { divide } from 'lodash';
import React from 'react';

const IconLogo = () => (
    <div>
      <title>Logo</title>
      <h2>🏠</h2>
    </div>
);

export default IconLogo;
